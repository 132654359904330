import React from 'react';

const Item = ({ image, bgcountry, name, desc, gradient, Country }) => {
  return (
    <div
      className='item wow fadeInUp'
      data-wow-duration='2s'
      data-wow-delay='.4s'
    >
      <a
        href={`/influencer/${name.toLowerCase().split(' ').join('-')}`}
        style={{ textDecoration: 'none' }}
      >
        <div
          className='img-box'
          style={{
            backgroundImage: `url('/img/${image}')`,
          }}
        >
          <span
            className='country'
            style={{
              backgroundImage: `url('/img/${bgcountry}')`,
            }}
          >
            {Country}
          </span>
        </div>
        <span className='name'>{name}</span>
        <span className='desc'>{desc}</span>
        <button
          className='read-more'
          style={{
            backgroundImage: `${gradient}`,
          }}
        >
          <div className='lines'>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </button>
      </a>
    </div>
  );
};

export default Item;
