import React, { useLayoutEffect } from 'react';
import headerC from '../assets/img/header/header-c.jpg';
import header from '../assets/img/header/100-infl.svg';
import $ from 'jquery';
import WOW from 'wowjs';
const Header = () => {
  const jQueryCode = () => {
    // setup wow js
    new WOW.WOW({
      live: false,
    }).init();
    // activate scroll
    setTimeout(() => {
      $('body').css('overflow', 'auto');
      $(window).scrollTop(0);
    }, 1000);
    // make preloader as tall as the header
    setTimeout(function () {
      let headerHeight = $('header').innerHeight();
      $('.preloader-box').removeClass('show');
      $('.preloader-box').css('height', headerHeight);
      console.log(headerHeight);
    }, 3000);
    // hide the preloader
    setTimeout(function () {
      $('.preloader-box').css('opacity', 0);
      $('.preloader-box').css('z-index', -1);
    }, 4000);
  };

  useLayoutEffect(() => {
    jQueryCode();
    // eslint-disable-next-line
  }, []);
  return (
    <header>
      <img src={headerC} alt='Header Background' className='bg-header' />
      <div className='overlay'>
        <img src={header} alt='text' />
      </div>
    </header>
  );
};
export default Header;
