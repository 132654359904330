import React from 'react';
import Header from '../components/Header';
import Preloader from '../components/Preloader';
import Description from '../components/Description';
import List from '../components/List';

import data from '../data/data';
const Home = ({ showText, handleClick }) => {
  return (
    <>
      <Header />
      <Preloader />
      {showText && <Description handleClick={handleClick} />}
      <List influences='leaders' array={data} />
      <List influences='entrepreneurs' array={data} />
      <List influences='changemakers' array={data} />
      <List influences='opinionshapers' array={data} />
      <List influences='creatives' array={data} />
      <List influences='sportspeople' array={data} />
    </>
  );
};

export default Home;
