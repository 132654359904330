import React from 'react';
import header from '../assets/img/header/100-infl.svg';

const Preloader = () => {
  return (
    <div className='preloader-box show'>
      <div className='content'>
        <div className='overlay'>
          <img src={header} alt='100 Influencers' />
        </div>
      </div>
    </div>
  );
};

export default Preloader;
