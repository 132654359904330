import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './components/Navbar';
import Home from './pages/Home';
import Error from './pages/Error';
import SingleInf from './pages/SingleInf';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

function App() {
  const [showText, setShowText] = useState(true);
  const handleClick = () => {
    setShowText(false);
  };

  return (
    <Router>
      <Navbar />
      <Routes>
        <Route
          path='/'
          element={<Home showText={showText} handleClick={handleClick} />}
        />
        <Route path='/influencer/:name' element={<SingleInf />} />
        <Route path='*' element={<Error />} />
      </Routes>
    </Router>
  );
}

export default App;
