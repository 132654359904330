import React from 'react';

const Description = ({ handleClick }) => {
  return (
    <section className='text'>
      <button className='close-text' onClick={handleClick}>
        <div className='lines'>
          <span></span>
          <span></span>
        </div>
      </button>
      <div className='container'>
        <div className='row'>
          <div className='col-md-6'>
            Welcome to our selection of the 100 Most Influential Africans of
            2021.<p></p>
            Over the years since we first started publishing this listing, its
            reach and significance have grown beyond our wildest expectations.{' '}
            <p></p>
            That is just how it should be and vindicates our decision to provide
            an annual showcase for Africa’s best and brightest. Recognising,
            honouring and celebrating the achievements of those who go the extra
            mile in their endeavours and whose efforts positively affect the
            lives of others near and far has long been a tradition in most
            societies.<p></p>
            Marking such distinction takes many forms – from community and
            industry to national honours. The British in particular have taken
            the awarding of ‘gongs’ to an almost mythical level, forever
            changing the lives, and often fortunes, of those who receive them.{' '}
            <p></p>
            Then the major international awards such as the Nobel Prize also
            create their own magic and many times raise often obscure
            individuals, toiling away unseen and unnoticed on their passions,
            into the full beam of the global spotlight – such as happened to our
            very own winner of this year’s Nobel Prize for Literature,
            Abdulrazak Gurnah. <p></p>
            At the international level, in parallel with the national and
            industry awards (such as the Oscars for film; Grammys for the
            recording industry and Emmys for TV) has been the massive popularity
            of the Most Influential awards – which cut across various
            disciplines as well as nationalities.<p></p>
            It is believed that the first version of this began with a book, The
            100: <i>A Ranking of the Most Influential Persons in History</i>, by
            Michael H. Hart in 1978. Hart’s criterion was how the course of
            human history was changed due to the actions of the people ranked –
            for better or for worse. <p></p>
            The book became very popular and was often used as reference
            material and to support or disprove arguments about historical
            figures. <i>Time magazine</i>, perhaps the best regarded publication
            not only in the US but around the world, picked up on it and in
            1999, published its first <i>Time 100</i> feature. <p></p>
            The people who appear on the list are recognised for doing something
            to change the world – regardless of the consequences of their
            actions. The final list of influencers is chosen by the editors of
            Time after consultations with their international staff as well as
            alumni of the list. The listing continues to have a powerful impact
            across the world.<p></p>
          </div>
          <div className='col-md-6'>
            <i>New African</i> has been published for over 50 years, charting
            the Africa story from the time of when the continent began to be
            liberated, through its growing pains to where it is today – but for
            most of this time, we swam against the current during an era when
            global news was controlled by the Western media. African affairs
            were relegated to the sidelines most of the time – unless there were
            wars – and analysis was often simplistic and negative. <p></p>
            African personalities did not make the news unless they were
            classNameified as dictators or projected as strange or weird. Even
            the athletes who won gold medals were portrayed as strange anomalies
            – their achievements the result of poverty or natural climatic
            conditions. Of the continent’s entrepreneurs, innovators, artists,
            writers, social workers, philosophers, thinkers, academics,
            adventurers, sportspeople in general, there was hardly ever a
            mention.<p></p>
            What was insidious was that apart from our publication, and a few
            others that followed, the pan-African communications space was owned
            by the large Western media –{' '}
            <i>
              The Times, The Financial Times, The Guardian, Le Monde, The
              Economist
            </i>{' '}
            and the US giants, CNN,<i> Time</i> and <i>Newsweek</i>. <p></p>
            They banged on about what the novelist Ngozi Chimamanda Adichie has
            called the ‘single story’ of African negativity. African achievement
            could not get a look-in. Given the scope of the Western press and
            the esteem it was held in, reinforced by powerful radio broadcasts
            that shaped the news and views of the day, many Africans came to
            believe in their own sense of worthlessness compared to others.{' '}
            <p></p>
            Some have described this as an enervating ‘colonial hangover’ that
            has been difficult to shake off even after generations.<p></p>
            But we who had come from different corners of Africa to London to
            work on the magazine, knew better. The Africa we knew was rich in
            character and humour and talent and we could not recognise the
            Africa that was being portrayed by the big publications. We set out
            to tell the African story the Africa way and to celebrate our
            Africanness – and we still do that today. While attitudes are
            changing, there is still a long way to go.<p></p>
            In this regard, our annual listing of the 100 Most Influential
            Africans has been playing a crucial role – as interest in the
            listing goes well beyond the continent’s borders. It gives us a
            chance to celebrate achievements and find hidden gems of talent and
            effort in our great continent. <p></p>
            Despite the lockdown and the havoc caused to everyday life and
            business by the pandemic, we have managed to find 100 inspiring
            stories to tell – including how the creatives, shunned from the
            global stage until recently, are now dominating it. <p></p>
            <i>Written and edited by Anver Versi</i>
            <p></p>
            <i>
              With{' '}
              <a
                target='_blank'
                rel='noreferrer'
                href='https://twitter.com/benyeoma?lang=en'
              >
                Omar Ben Yedder
              </a>
              , Gail Collins, Neil Ford, Clayton Goodwin, Wanjohi Kabukuru,{' '}
              <a
                target='_blank'
                rel='noreferrer'
                href='https://twitter.com/B_shosh'
              >
                Shoshana Kedem
              </a>
              , Michael Renouf and{' '}
              <a
                target='_blank'
                rel='noreferrer'
                href='https://twitter.com/davethomas1989?lang=en'
              >
                David Thomas
              </a>
              .
            </i>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Description;
