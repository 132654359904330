import React, { useState } from 'react';
import logoImage from '../assets/img/logo.svg';
import MenuOpen from '../assets/img/menu-open.svg';
import MenuClose from '../assets/img/menu-close.svg';

const Navbar = () => {
  const [showMenu, setShowMenu] = useState(false);
  const openMenu = () => {
    setShowMenu(true);
  };
  const closeMenu = () => {
    setShowMenu(false);
  };

  return (
    <>
      <nav className='navbar navbar-expand-xl navbar-light fixed-top'>
        <a
          className='navbar-brand'
          target='_blank'
          rel='noreferrer'
          href='https://newafricanmagazine.com/'
        >
          <img src={logoImage} alt='Logo' />
        </a>
        <button
          className='navbar-toggler'
          type='button'
          data-toggle='collapse'
          data-target='#navbarNavAltMarkup'
          aria-controls='navbarNavAltMarkup'
          aria-expanded='false'
          aria-label='Toggle navigation'
        >
          <span className='navbar-toggler-icon'></span>
        </button>
        <div className='collapse navbar-collapse' id='navbarNavAltMarkup'>
          <div className='navbar-nav'>
            <a className='nav-item nav-link' href='/#leaders'>
              Leaders
            </a>
            <a className='nav-item nav-link' href='/#entrepreneurs'>
              Entrepreneurs
            </a>
            <a className='nav-item nav-link' href='/#changemakers'>
              Changemakers
            </a>
            <a className='nav-item nav-link' href='/#opinion_shapers'>
              Opinion Shapers
            </a>
            <a className='nav-item nav-link' href='/#creatives'>
              Creatives
            </a>
            <a className='nav-item nav-link' href='/#sportspeople'>
              Sportspeople
            </a>
            <a
              className='nav-item nav-link'
              href='http://100.newafricanmagazine.com/'
              target='_blank'
              rel='noreferrer'
            >
              2020 LIST
            </a>
          </div>
          <div className='navbar-nav soc-links'>
            <a
              href='https://www.facebook.com/NewAfricanMag/'
              target='_blank'
              rel='noreferrer'
            >
              <i className='fab fa-facebook-f'></i>
            </a>
            <a
              href='https://twitter.com/NewAfricanMag'
              target='_blank'
              rel='noreferrer'
            >
              <i className='fab fa-twitter'></i>
            </a>
            <a
              href='http://www.linkedin.com/company/ic-publications/'
              target='_blank'
              rel='noreferrer'
            >
              <i className='fab fa-linkedin-in'></i>
            </a>
            <a
              href='https://www.youtube.com/user/ICEventsLondon'
              target='_blank'
              rel='noreferrer'
            >
              <i className='fab fa-youtube'></i>
            </a>
          </div>
        </div>
      </nav>

      {/* Navbar Menu Mobile  */}
      <nav className='navbar navbar-expand-lg navbar-light fixed-top nav-mobile'>
        <a className='navbar-brand' href='/'>
          <img src={logoImage} alt='Logo' />
        </a>
        {showMenu ? (
          <button>
            <img
              src={MenuClose}
              alt='Close Menu'
              className={`${showMenu ? 'showBtn' : 'hideBtn'}`}
              onClick={closeMenu}
            />
          </button>
        ) : (
          <button>
            <img
              src={MenuOpen}
              alt='Open Menu'
              className={`${showMenu ? 'hideBtn' : 'showBtn'}`}
              onClick={openMenu}
            />
          </button>
        )}
      </nav>

      <div className={`menu-mobile ${showMenu && 'show'}`}>
        <div className='content'>
          <ul>
            <li>
              <a href='/#leaders' className='b-pol' onClick={closeMenu}>
                Leaders
              </a>
            </li>
            <li>
              <a href='/#entrepreneurs' className='b-fin' onClick={closeMenu}>
                Entrepreneurs
              </a>
            </li>
            <li>
              <a href='/#changemakers' className='b-civ' onClick={closeMenu}>
                Changemakers
              </a>
            </li>
            <li>
              <a href='/#opinion_shapers' className='b-inv' onClick={closeMenu}>
                Opinion Shapers
              </a>
            </li>
            <li>
              <a href='/#creatives' className='b-inv' onClick={closeMenu}>
                Creatives
              </a>
            </li>
            <li>
              <a href='/#sportspeople' className='b-inv' onClick={closeMenu}>
                Sportspeople
              </a>
            </li>
            <li>
              <a
                href='http://100.newafricanmagazine.com/'
                className='b-md'
                target='_blank'
                rel='noreferrer'
              >
                2020 LIST
              </a>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default Navbar;
