import React from 'react';
import { Link } from 'react-router-dom';

const Error = () => {
  return (
    <div style={{ background: '#ccc' }}>
      <div
        style={{
          height: '100vh',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
        className='container text-dark'
      >
        <h1 style={{ fontSize: '12rem', textAlign: 'center' }}>404</h1>
        <h1 style={{ letterSpacing: '5px', textAlign: 'center' }}>
          Sorry, The Page You Tried Cannot Be Found
        </h1>
        <Link to='/' className='btn btn-danger mt-3'>
          Back to Home
        </Link>
      </div>
    </div>
  );
};

export default Error;
