import React from 'react';
import Item from './Item';

const List = ({ influences, array }) => {
  let category = influences[0].toUpperCase() + influences.slice(1);
  return (
    <section
      className={`sec-infls ${
        influences === 'opinionshapers' && 'opinion_shapers'
      }`}
      id={`${influences === 'opinionshapers' ? 'opinion_shapers' : influences}`}
    >
      <div className='container'>
        <div className='title-box'>
          <h4
            className='title'
            style={{
              backgroundImage:
                influences === 'leaders'
                  ? 'linear-gradient(90deg,#fc770a,#fd9a48)'
                  : influences === 'entrepreneurs'
                  ? 'linear-gradient(90deg,#A65B00,#C88330)'
                  : influences === 'entrepreneurs'
                  ? 'linear-gradient(90deg,#A65B00,#C88330)'
                  : influences === 'changemakers'
                  ? 'linear-gradient(90deg,#AB2002,#DD4A2A)'
                  : influences === 'opinionshapers'
                  ? 'linear-gradient(90deg,#992568,#B25189)'
                  : influences === 'creatives'
                  ? 'linear-gradient(90deg,#A65B00,#C88330)'
                  : influences === 'sportspeople'
                  ? 'linear-gradient(90deg,#1891B6,#4EC0E3)'
                  : '',
            }}
          >
            {category === 'Opinionshapers' ? 'Opinion Shapers' : category}
          </h4>
        </div>
        <div className='list-infls'>
          {array.map(
            (item, index) =>
              item.category === category && <Item key={index} {...item} />
          )}
        </div>
      </div>
    </section>
  );
};

export default List;
