import React, { useEffect, useState } from 'react';
import data from '../data/data';
import { useParams, useNavigate } from 'react-router-dom';
import btnImage from '../assets/img/btn-back.svg';
import Slider from 'react-slick';

const SingleInf = () => {
  let navigate = useNavigate();
  const { name } = useParams();
  const [influencer, setInfluencer] = useState({});
  const [show, setShow] = useState('');
  const [celebrities, setCelebrities] = useState([]);
  let Name = name.toLowerCase().split('-').join(' ');
  const SlickArrowLeft = ({ className, style, onClick }) => (
    <div
      className={className}
      style={{
        ...style,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '15px 0',
        borderRadius: '8px',
        background: '#dedede',
        color: '#fff',
        width: '50px',
        transform: 'translateY(-55px)',
        zIndex: 1,
      }}
      onClick={onClick}
    />
  );

  const SlickArrowRight = ({ className, style, onClick }) => (
    <div
      className={className}
      style={{
        ...style,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '15px 0',
        borderRadius: '8px',
        background: '#dedede',
        color: '#fff',
        width: '50px',
        zIndex: 1,
        transform: 'translateY(-55px)',
        marginBottom: '2rem',
      }}
      onClick={onClick}
    />
  );

  const fetchData = () => {
    const filteredInf = data.filter(
      (inf) =>
        inf.category === influencer.category && inf.name !== influencer.name
    );
    setCelebrities(filteredInf);
    console.log(filteredInf);
  };

  const fetchCategory = () => {
    const filtered = data.find((person) => {
      console.log(person.name.toLowerCase().split(' '));
      return person.name.toLowerCase().split(/-| /).join(' ') === Name;
    });
    setInfluencer(filtered);
    if (!filtered) {
      navigate('/');
    }
  };
  useEffect(() => {
    fetchCategory();
    setTimeout(() => {
      setShow('show');
    }, 500);
    // eslint-disable-next-line
  }, [name]);
  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, [influencer]);
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    prevArrow: <SlickArrowLeft />,
    nextArrow: <SlickArrowRight />,
    responsive: [
      {
        breakpoint: 990,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <>
      <div id='desc-page' className={show}>
        <div className='container'>
          <div className='back-row'>
            <a href='/' className='back-home' target='_parent'>
              <img src={btnImage} alt='Back' />
            </a>
          </div>
          <div className='content-box'>
            <div className='left-side'>
              <div className='name-box'>
                <h1 className='name'>{influencer.name}</h1>
              </div>
              <span className='desc'> {influencer.desc} </span>
              <span
                className='category'
                style={{ backgroundImage: influencer.gradient }}
              >
                {influencer.category === 'Opinionshapers'
                  ? 'Opinion Shapers'
                  : influencer.category}
              </span>
              <p
                className='text'
                dangerouslySetInnerHTML={{ __html: influencer.Text }}
              ></p>
            </div>
            <div className='right-side'>
              <div
                className='img-box'
                style={{
                  backgroundImage: `url('/img/${influencer.image}')`,
                }}
              >
                <div
                  className='country'
                  style={{
                    backgroundImage: `url('/img/${influencer.bgcountry}')`,
                  }}
                >
                  {influencer.Country}
                </div>
              </div>
            </div>
          </div>
          <div className={`sec-infls ${influencer.category}`}>
            <h1 className='heading-Slider'>
              Other{' '}
              <span style={{ color: influencer.mainColor }}>
                {influencer.category === 'Opinionshapers'
                  ? 'Opinion Shapers'
                  : influencer.category}
              </span>{' '}
              Influencers
            </h1>
            <Slider {...settings}>
              {celebrities.map((celeb, index) => {
                return (
                  <div className='item px-2' key={index}>
                    <a
                      href={`/influencer/${celeb.name
                        .toLowerCase()
                        .split(' ')
                        .join('-')}`}
                      style={{ textDecoration: 'none' }}
                    >
                      <div
                        className='img-box'
                        style={{
                          backgroundImage: `url('/img/${celeb.image}')`,
                        }}
                      >
                        <span
                          className='country'
                          style={{
                            backgroundImage: `url('/img/${celeb.bgcountry}')`,
                          }}
                        >
                          {celeb.Country}
                        </span>
                      </div>
                      <span className='name'>{celeb.name}</span>
                      {/* <span className='desc'>{celeb.desc}</span> */}
                      <button
                        className='read-more'
                        style={{
                          backgroundImage: `${celeb.gradient}`,
                        }}
                      >
                        <div className='lines'>
                          <span></span>
                          <span></span>
                          <span></span>
                        </div>
                      </button>
                    </a>
                  </div>
                );
              })}
            </Slider>
          </div>
        </div>
      </div>
    </>
  );
};

export default SingleInf;
